<template>
  <v-snackbar
    v-model="show"
    top
  >
    <span v-for="(error, key) in validationErrors" :key="key">{{ error }}<br></span>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ValidationErrors',

  computed: {
    show: {
      get () {
        return this.validationErrors
      },
      set () {
      },
    },
    ...mapState(['validationErrors']),
  },
}
</script>
